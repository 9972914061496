import { trans } from 'trans-js'
import { computed } from "vue";
import { usePage } from '@inertiajs/vue3'
export default {
    install: (app, options) => {
        const page = usePage();
        const locale = computed(() => page.props?.locale|| 'ar')
        const configuration = computed(() => ({
            locale: locale.value,
            Lingua: options.Lingua
        }))

        const translationCallback = (key, replace, config = configuration) => {
            let translated = trans('site.' + key, replace, false, {
                Lingua: configuration.value.Lingua,
                locale: configuration.value.locale
            });
            return translated.replace('site.', '');
        }



        app.config.globalProperties.__t = translationCallback;

        app.mixin({
            methods: {
                __t: translationCallback,
            },
        });

        app.provide('__t', translationCallback);
    }
};
